@import '@/styles/breakpoints.module';

.similarAssetsList {
  li {
    border-radius: 8px;
    &:hover {
      background-color: var(--palette-primary-75);
    }
    &:active {
      background-color: var(--palette-primary-100);
    }
  }
}

.link {
  display: flex;
  gap: 16px;
  padding: 8px;

  .title {
    font-weight: var(--palette-font-weight-medium);
  }

  .icon {
    margin-top: 4px;
  }

  .text {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .priceAndChange {
    text-align: end;
  }

  .change {
    font-weight: var(--palette-font-weight-semibold);
  }

  .price {
    font-weight: var(--palette-font-weight-medium);
    color: var(--palette-gray-700);
  }
}
