@mixin textLink_ {
  color: var(--color-btn-link-text);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--color-btn-link-hover-text);
  }

  &:active {
    color: var(--color-btn-link-active-text);
  }

  &:focus {
    outline: none;
  }
}

.textLink {
  @include textLink_;
}

.underline {
  text-decoration: underline;
}

.breakWord {
  word-break: break-word;
}

.noWrap {
  white-space: nowrap;
}
