@import '@/styles/breakpoints.module';

.container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 !important;
}

.search {
  width: 100%;
  margin: 0 auto;
}
