@import '@/styles/breakpoints.module';

.languageSelector {
  overflow-x: auto;
}

.languageButtons {
  display: flex;
  gap: 4px;
  padding-top: 4px;
  padding-bottom: 4px;

  @include tablet {
    justify-content: center;
  }
}

.languageButton {
  flex-shrink: 0;
}

.disclaimerWrapper {
  border-radius: var(--palette-border-radius-s);
  background: var(--palette-gray-100);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;

  p {
    max-width: 836px;
    width: 100%;
    text-align: center;
  }
}
