@import '@/styles/breakpoints.module';

.heroDisclaimer {
  margin-top: 12px;

  @include tablet {
    margin-top: 16px;
    text-align: center;
  }
}
