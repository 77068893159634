@import '@/styles/breakpoints.module';

.steps {
  --step-indicator-width: 16px;
  --step-indicator-border-width: 3px;
  --step-indicator-border-color: var(--palette-gray-150);
  --step-indicator-hole-color: var(--palette-white);
  --step-line-color: var(--palette-gray-150);

  --space-btw-line-end-step: 16px;
  --space-btw-steps: 8px;
  --dash-btw-step-indicator-and-line: 2px;

  @include tablet {
    --space-btw-line-end-step: 28px;
    --space-btw-steps: 16px;
    --step-indicator-border-width: 4px;
    --step-indicator-width: 24px;
  }

  @include desktop {
    --space-btw-line-end-step: 36px;
  }

  padding-inline-start: 12px;

  .stepIndicator {
    position: absolute;
    display: block;
    top: 0;
    inset-inline-end: calc(100% - ((var(--step-indicator-width) / 2) - 1px));
    background-color: var(--palette-white);
    width: var(--step-indicator-width);
    height: calc(var(--step-indicator-width) + var(--dash-btw-step-indicator-and-line));
  }

  .stepIndicatorDot {
    display: block;
    border-radius: 100%;
    width: calc(var(--step-indicator-width));
    height: calc(var(--step-indicator-width));
    border: var(--step-indicator-border-width) solid var(--step-indicator-border-color);
    transition:
      background-color 150ms ease-in-out,
      border-color 90ms ease-in-out;
    background-color: var(--step-indicator-hole-color);
  }

  .step {
    position: relative;

    &:hover {
      --step-indicator-border-color: var(--palette-primary-700);
      --step-indicator-hole-color: var(--palette-primary-700);
    }

    &:not(:last-child) {
      margin-bottom: var(--dash-btw-step-indicator-and-line);
      padding-bottom: calc(var(--space-btw-steps) - var(--dash-btw-step-indicator-and-line));
    }

    padding-inline-start: var(--space-btw-line-end-step);
    border-inline-start: 1px solid var(--step-line-color);
  }
}
