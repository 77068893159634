@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.tradingGrid {
  display: grid;
  gap: 16px;

  @include tablet {
    grid-template-columns: 1fr 1fr;

    & > *:first-child {
      grid-column: 1 / -1;
    }
  }

  > div {
    background-color: var(--palette-white);
    border-radius: var(--palette-border-radius-m);

    padding: 16px;

    @include desktop {
      padding: 24px;
    }
  }
}

.tradingIndicators {
  display: grid;

  row-gap: 12px;

  @include tablet {
    row-gap: 16px;
    column-gap: 8px;

    grid-template-columns: 1fr 1fr 1fr;

    & > *:first-child, & > *:last-child {
      grid-column: 1 / -1;
    }
  }

  @include desktop {
    row-gap: 24px;
    column-gap: 16px;
  }

  > div {
    text-align: center;
    background-color: var(--palette-gray-70);
    border-radius: var(--palette-border-radius-m);

    padding: 16px;

    @include desktop {
      padding: 24px;
    }
  }
}

.indicatorsTitle {
  @include typography.h6Mobile_;
  @include tablet {
    @include typography.h6Tablet_;
  }
}

.verticalCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.infoCard {
  p {
    @include typography.h6Tablet_;
  }
}

.disclaimer {
  text-align: center;
}

.weightAccent {
  font-weight: var(--palette-font-weight-semibold);
}

.infoCardSubheading {
  color: var(--palette-gray-500) !important;
}
