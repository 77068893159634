@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.tabs.tabs {
  margin-bottom: 24px;
  @include tablet {
    margin-bottom: 32px;
  }
  @include desktop {
    margin-bottom: 40px;
  }
}
