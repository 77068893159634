@import '@/styles/breakpoints.module';

.tradesContainer {
  margin-top: 32px;
  max-height: 1000px;
  overflow: auto;

  @include desktop {
    margin-top: 48px;
  }

  & > *:hover * {
    --trade-card-bg-color: var(--palette-gray-70);
  }
}
