@import '@/styles/breakpoints.module';

.storeButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-btn-social);
  cursor: pointer;
  transition:
    background-color 200ms,
    opacity 200ms;
  transition-timing-function: var(--store-button-transition-fn);

  img {
    width: auto;
  }
}

.dark {
  background-color: var(--color-btn-social-filled-bg);

  &:hover {
    background-color: var(--color-btn-social-filled-hover-bg);
  }
}

.light {
  background-color: var(--palette-white);

  &:hover {
    opacity: 0.8;
  }
}

.sizeS {
  min-width: 93px;
  height: 32px;
  padding: 0;

  img {
    transform: scale(0.72);
  }
}

.sizeM {
  min-width: 140px;
  height: 48px;
  padding: 10px;
}
