@import '@/styles/breakpoints.module';

.qrPopup,
.qrPopup {
  position: absolute;
  min-width: 201px;
  width: 100%;
  z-index: 1;
  bottom: -2px;
  inset-inline-start: 100%;
  transition: all 0.3s ease-in-out;
  text-align: center;
  pointer-events: none;
  padding-inline-start: 9px;

  opacity: 0;
  visibility: hidden;
  transform: translateY(-5%);

  .qrPopupInner {
    padding: 16px;
    border-radius: var(--palette-border-radius-m);
    background: var(--palette-gray-700);

    img {
      margin-top: 16px;
    }

    p {
      color: var(--palette-white);
    }
  }
}

.qrCodeButton {
  width: 56px;
  height: 56px;
  padding: 12px;
  border-radius: var(--palette-border-radius-xs);
  border: 1px solid var(--palette-gray-500);
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--color-btn-social-outline-hover-border);
    .qrPopup {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
}
