@import '@/styles/breakpoints.module';

.wrapper {
  ol {
    counter-reset: item;

    li {
      position: relative;
      counter-increment: item;

      &:before {
        content: counter(item) '. ';
      }
    }
  }

  ul {
    padding-left: 25px;

    li {
      position: relative;
    }

    li::before {
      content: '•';
      color: var(--color-btn-social-filled-hover-bg);
      position: absolute;
      left: -12px;
      width: 3px;
      height: 3px;
    }
  }

  ol {
    padding-left: 8px;
    counter-reset: item;
  }
}

.bold {
  font-weight: var(--palette-font-weight-semibold);
}

.link {
  color: var(--palette-gray-600);
}
