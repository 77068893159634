@import '@/styles/breakpoints.module';

.todayCardWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--palette-white);
  border-radius: var(--palette-border-radius-m);
  box-shadow: 2px 4px 60px 0px var(--copy-trade-shadow);
  overflow-wrap: anywhere;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  padding: 16px;
  gap: 16px;
  transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  & > * {
    width: 100%;
  }

  &:hover {
    border-color: var(--palette-primary-700);
  }

  @include tablet {
    padding: 24px;
    gap: 24px;
  }

  @include desktop {
    gap: 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 60px;
  }
}

.todayCardProfile {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  @include desktop {
    max-width: 547px;
  }
}

.todayCardAccount {
  display: flex;
  gap: 24px;
}

.chart {
  width: 100%;
  height: 112px;
  @include tablet {
    height: 157px;
  }
}

.todayCardAccountInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  @include tablet {
    gap: 16px;
  }
}

.todayCardAccountInfoPosition,
.todayCardAccountInfoFollowers {
  color: var(--palette-gray-400);
}

.todayCardStatistics {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;

  @include desktop {
    max-width: 413px;
  }

  .todayCardStatisticsItems {
    display: grid;
    grid-template-columns: auto 1fr;

    & > * {
      padding-block: 16px;
      border-bottom: 1px solid var(--palette-gray-150);
      @include tablet {
        padding-block: 24px;
      }

      &:nth-child(odd) {
        padding-inline: 16px;
      }
    }
  }
}

.topTraderCardCta {
  width: 100%;
  @include tablet {
    width: fit-content;
  }
  @include desktop {
    width: 100%;
  }
}
