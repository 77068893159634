@import '@/styles/breakpoints.module';

.headingWrapper {
  margin-bottom: 32px;
  @include tablet {
    margin-bottom: 44px;
  }

  @include desktop {
    margin-bottom: 60px;
  }
}
