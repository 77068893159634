@import '@/styles/breakpoints.module';

.bannerWrapper {
  display: grid;
  align-items: center;

  grid-template-areas: 1fr;
  padding: 32px 16px;
  background-color: var(--palette-gray-900);
  gap: 32px;

  border-radius: var(--palette-border-radius-s);

  grid-template: 'heading' 'img' 'cta';
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  @include tablet {
    padding: 60px 40px;
    grid-template:
      'heading heading'
      'img cta';
    border-radius: var(--palette-border-radius-m);
  }

  @include desktop {
    padding: 60px 80px;
    grid-template-columns: 1fr 304px 144px;
    grid-template: 'heading img cta';
    gap: 60px;
  }

  &:hover {
    background-color: var(--palette-gray-700);
  }
}

.image {
  grid-area: img;
  margin: 0 auto;
  max-width: 304px;
  width: 100%;

  @include tablet {
    width: 304px;
  }
}

.ctaWrapper {
  grid-area: cta;

  color: var(--palette-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  span {
    color: var(--palette-gray-200);
  }

  p {
    color: inherit;
  }
}
