@import '@/styles/breakpoints.module';

.heading {
  margin: var(--vstack-spacing-sm) auto;

  @include tablet {
    margin: var(--vstack-spacing-md) auto;
  }

  @include desktop {
    margin: var(--vstack-spacing-lg) auto;
  }
}

.ctaWrapper {
  display: flex;
  justify-content: center;
  margin-top: var(--vstack-spacing-sm);

  @include tablet {
    margin-top: var(--vstack-spacing-md);
  }

  @include desktop {
    margin-top: var(--vstack-spacing-lg);
  }

  a {
    width: 100%;

    @include tablet {
      width: unset;
    }
  }
}

.boldAccent {
  font-weight: var(--palette-font-weight-semibold);
}
